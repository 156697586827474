<template>
  <v-snackbar
    v-model="showSnackBar"
    :timeout="timeout"
    top
    right
    :color="status"
  >
    <span class="white--text font font-size-md font-weight-medium">{{
      message
    }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" color="white" @click="close">Close</v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SnackBar",
  props: {
    showSnackBar: {
      type: Boolean,
      default: false
    },
    status: {
      type: String
    },
    message: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      timeout: "getSnackBarTimeout"
    })
  },
  methods: {
    close() {
      this.$store.dispatch(
        "showSnackBar",
        {
          snackBar: false,
          message: "",
          status: ""
        },
        { root: true }
      );
    }
  }
};
</script>
